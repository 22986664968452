<template>
	<IonCard
		class="card"
		:router-link="`/kurs/${kurs.id}/${$slugify(kurs.name)}`"
	>
		<IonGrid>
			<ion-row class="ion-align-items-center">
				<ion-col class="img-col" size="2" size-xl="1">
					<ion-avatar class="image">
						<img :src="imgLink" />
					</ion-avatar>
				</ion-col>
				<ion-col
					size-sm="10"
					size-md="10"
					size-lg="10"
					size-xl="11"
					size="10"
					class="ion-padding"
				>
					<h2 class="slabs">{{ kurs.name }}</h2>
					<div class="text">{{ kurs.beschreibung }}</div>
					<div class="label">
						{{ doneKapitel }}/{{ kurs.kapitel.length }} Kapitel
					</div>
					<IonProgressBar
						:color="proColour"
						:value="kapitelCompleted"
					></IonProgressBar>
				</ion-col>
			</ion-row>
		</IonGrid>
		<span class="advertorial adtag" v-if="kurs.advertorial"
			>GESPONSERT</span
		>
	</IonCard>
</template>

<script>
import {
	IonCard,
	// IonImg,
	IonAvatar,
	IonCol,
	IonGrid,
	IonRow,
	IonProgressBar,
} from '@ionic/vue';

export default {
	name: 'KursCard',
	props: {
		kurs: Object,
		progress: String,
	},
	components: {
		IonCard,
		// IonImg,
		IonAvatar,
		IonCol,
		IonProgressBar,
		IonGrid,
		IonRow,
	},
	computed: {
		imgLink() {
			return `${this.$store.state.imageBase}${
				this.kurs.icon || this.kurs.featured_image
			}?key=kursicon&access_token=${localStorage.getItem('auth_token')}`;
		},
		doneKapitel() {
			if (
				!this.$store.state.user.progress ||
				!this.$store.state.user.progress[this.kurs.id]
			)
				return 0;
			return this.$store.state.user.progress[this.kurs.id].doneKapitel;
		},
		proColour() {
			let color = '';
			let prog = parseFloat(this.kapitelCompleted);
			switch (prog) {
				case 0:
					color = 'dark';
					break;
				case 1:
					color = 'primary';
					break;
				default:
					color = 'secondary';
			}
			return color;
		},
		kapitelCompleted() {
			return this.doneKapitel / this.kurs.kapitel.length;
		},
	},
};
</script>

<style lang="scss" scoped>
.card {
	cursor: pointer;

	ion-avatar {
		box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.249508);
	}

	.img-col {
		ion-img::part(image) {
			max-height: 80px;
		}
	}

	h2 {
		font-weight: bold;
		font-size: 17px;
		line-height: 22px;
		letter-spacing: 0.7px;
		color: #131313;
		margin: 0px;
	}

	.text {
		margin: 10px 0;
		font-size: 14px;
	}

	.label {
		font-size: 14px;
		line-height: 16px;
		color: #898989;
		margin-bottom: 5px;
	}

	.adtag {
		position: absolute;
		top: 0;
		right: 0;
		padding: 0.2rem;
	}
}
</style>
