<template>
	<div class="modal">
		<ion-header>
			<ion-toolbar>
				<ion-title>Kategorie wählen</ion-title>
				<ion-buttons slot="end">
					<ion-button @click="dismissModal()">
						<ion-icon
							slot="icon-only"
							color="black"
							:icon="closeOutline"
						></ion-icon>
					</ion-button>
				</ion-buttons>
			</ion-toolbar>
		</ion-header>
		<ion-content :fullscreen="true">
			<ion-grid class="tabbar-b-margin">
				<ion-row>
					<ion-col v-for="kat in kategorien" :key="kat">
						<ion-chip
							color="primary"
							@click="selectCat(kat)"
							outline
							:class="{ selected: kat.selected }"
						>
							<ion-icon
								:icon="checkmarkCircle"
								v-if="kat.selected"
							></ion-icon>
							<ion-icon :icon="ellipseOutline" v-else></ion-icon>
							<ion-label class="katname">{{
								kat.name
							}}</ion-label>
						</ion-chip>
					</ion-col>
				</ion-row>
			</ion-grid>

			<ion-button
				expand="block"
				color="black"
				class="ion-margin avatarbtn"
				:disabled="selected === ''"
				@click="filterList()"
				>Filtern</ion-button
			>
		</ion-content>
	</div>
</template>

<script>
import {
	IonContent,
	IonHeader,
	IonTitle,
	IonToolbar,
	IonButtons,
	IonButton,
	IonIcon,
	IonLabel,
	IonGrid,
	IonChip,
	IonRow,
	IonCol,
	modalController,
} from '@ionic/vue';
import { closeOutline, checkmarkCircle, ellipseOutline } from 'ionicons/icons';

export default {
	name: 'AvatareModal',
	props: {
		kategorien: Array,
	},
	components: {
		IonContent,
		IonHeader,
		IonTitle,
		IonToolbar,
		IonChip,
		IonLabel,
		IonButtons,
		IonButton,
		IonIcon,
		IonGrid,
		IonRow,
		IonCol,
	},
	data() {
		return {
			selected: [],
		};
	},
	methods: {
		dismissModal() {
			modalController.dismiss();
		},
		selectCat(cat) {
			cat.selected = !cat.selected;
		},

		filterList() {
			let filteredIds = [];
			for (let item of this.kategorien) {
				if (item.selected) filteredIds.push(item.id);
			}
			modalController.dismiss(filteredIds);
		},
	},
	setup() {
		return { closeOutline, checkmarkCircle, ellipseOutline };
	},
};
</script>

<style lang="scss" scoped>
.ion-page {
	background: white;
}

.avatarbtn {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
}

ion-grid {
	margin-top: 15px;
	padding: 15px;
}

ion-col {
	padding-inline-start: 0px;
}

ion-chip {
	margin-inline: 0px;
}

.selected {
	background: var(--ion-color-secondary);
}

.katname {
	white-space: nowrap;
}

ion-content::part(scroll) {
	padding-bottom: 30px;
}
</style>
