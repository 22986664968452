<template>
	<ion-page>
		<PageHeader :title="'Lern-Kurse'"></PageHeader>
		<ion-content id="kurse" class="inset-container" :fullscreen="true">
			<ion-toolbar class="kursbar">
				<ion-searchbar
					:value="searchInput"
					mode="ios"
					placeholder="Suche"
					class="searchbar"
					@ionInput="searchInput = $event.target.value"
				></ion-searchbar>
				<ion-buttons slot="end">
					<ion-button @click="openKategorieModal">
						<ion-icon
							slot="icon-only"
							:icon="funnelOutline"
							color="primary"
						></ion-icon>
					</ion-button>
				</ion-buttons>
			</ion-toolbar>
			<kurs-card
				:kurs="kurs"
				progress="0"
				v-for="kurs in filteredKurse"
				:key="kurs.id"
			></kurs-card>
		</ion-content>
		<TabBar></TabBar>
	</ion-page>
</template>

<script>
import {
	IonContent,
	IonPage,
	IonButtons,
	IonButton,
	IonIcon,
	IonSearchbar,
	IonToolbar,
	modalController,
} from '@ionic/vue';
import { funnelOutline } from 'ionicons/icons';

import KursCard from '@/components/KursCard.vue';
import TabBar from '@/components/TabBar.vue';
import PageHeader from '@/components/PageHeader.vue';
import KategorieModal from '@/components/KategorieModal';

export default {
	name: 'Kurse',
	components: {
		IonContent,
		IonPage,
		KursCard,
		TabBar,
		PageHeader,
		IonButtons,
		IonButton,
		IonIcon,
		IonSearchbar,
		IonToolbar,
	},
	data() {
		return {
			allKurse: [],
			filteredKurse: [],
			kategorien: [],
			filterKat: [],
			searchInput: '',
		};
	},
	watch: {
		async searchInput(newVal) {
			if (!newVal) {
				this.filteredKurse = this.allKurse;
				this.filterKat = [];
				return;
			}
			let filterThese = this.allKurse;
			if (this.filterKat.length) {
				filterThese = await this.filterKurse(this.filterKat);
			}
			const filter = newVal.toLowerCase();
			const filteredArray = filterThese.filter(
				(obj) =>
					obj.name.toLowerCase().includes(filter) ||
					obj.categories.includes(filter)
			);
			this.filteredKurse = filteredArray;
		},
	},
	methods: {
		async ionViewWillEnter() {
			const { data } = await this.$http.get(
				'/items/kurse?fields=*,kategorien.*.*&sort=sort'
			);
			this.searchInput = '';

			// create easily searchable categories
			// add categories for filter modal
			for (let item of data.data) {
				item.categories = [];
				for (let cat of item.kategorien) {
					item.categories.push(cat.kurskategorien_id.Name);
					if (
						this.kategorien.some(
							(e) => e.name === cat.kurskategorien_id.Name
						)
					)
						continue;
					else {
						this.kategorien.push({
							name: cat.kurskategorien_id.Name,
							id: cat.kurskategorien_id.id,
							selected: false,
						});
					}
				}
				item.categories = item.categories.toString().toLowerCase();
			}

			// find advertorials and kurse
			const advertorials = data.data.filter((kurs) => kurs.advertorial);
			const kurse = data.data.filter((kurs) => !kurs.advertorial);

			// Find in process kurse from status
			let progressKurse = [];
			for (let kurs in this.$store.state.user.progress) {
				if (!this.$store.state.user.progress[kurs].done)
					progressKurse.push(kurs);
			}

			// Sort in process kurse to top
			kurse.sort(
				(a, b) =>
					progressKurse.indexOf(b.id.toString()) -
					progressKurse.indexOf(a.id.toString())
			);

			// Add advertorials in index 3 and 5
			let index = 2;
			for (let advert of advertorials) {
				kurse.splice(index, 0, advert);
				index = index + 2;
			}

			this.allKurse = kurse;
			this.filteredKurse = kurse;
		},
		async filterKurse(filter) {
			this.filteredKurse = [];
			for (let kurs of this.allKurse) {
				if (
					kurs.kategorien.filter((kat) =>
						filter.includes(kat.kurskategorien_id.id)
					).length
				)
					this.filteredKurse.push(kurs);
			}
			return this.filteredKurse;
		},
		async openKategorieModal() {
			const kategorien = this.kategorien;
			const modal = await modalController.create({
				component: KategorieModal,
				componentProps: { kategorien },
			});

			await modal.present();

			const modalResponse = await modal.onDidDismiss();
			this.filterKat = modalResponse.data;
			if (modalResponse.data && modalResponse.data.length)
				this.filterKurse(modalResponse.data);
			else this.filteredKurse = this.allKurse;
		},
	},
	setup() {
		return { funnelOutline };
	},
};
</script>

<style lang="scss" scoped>
ion-content {
	--padding-top: 5px;
	--padding-start: 5px;
	--padding-bottom: 90px;
}

.kursbar {
	margin: 5px;
	width: auto;
	filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.249508));
	margin-bottom: 10px;

	.searchbar {
		padding-top: 0;
		padding-bottom: 0;
		height: 60px;
	}
}
</style>
